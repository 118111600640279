import { IIncidentReportRead } from 'common/services/incident-report/incident-report.types';
import { IIncidentLocationEditProps, IIncidentLocationFormValues } from './incident-location-edit.types';
import { Col, DatePicker, Form, Row, Select, TimePicker } from 'antd';
import configurationStore from 'common/stores/configuration/configuration.store';
import { useEffect, useMemo, useState } from 'react';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import IncidentEditModeActions from '../../incident-edit-mode-actions/incident-edit-mode-actions';
import { useAddressSelect } from 'common/hooks/use-address-select/use-address-select.hook';
import { incidentLocationFormRules } from './incident-location-edit.validation';
import { IAddress } from 'common/services/street-service/street.service.types';
import { SelectProps } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';
import { TIME_h_mma } from 'common/constans/date.constants';

export const convertToFormValues = (incident: IIncidentReportRead | undefined): IIncidentLocationFormValues | undefined => {
  if (!incident) {
    return undefined;
  }

  return {
    incidentDate: incident.incidentDate,
    gate: incident.gate,
    address: {
      addressId: incident.address?.id || 0,
      streetName: incident.address?.street?.name || '',
      streetNumber: incident.address?.streetNumber || '',
      communityName: incident.address?.street?.community?.name || '',
    },
  };
};

const IncidentLocationEdit = ({ incident, onSave, onCancel, form }: IIncidentLocationEditProps) => {
  const initialDateValue = incident?.incidentDate ? new Date(incident?.incidentDate * 1000) : undefined;

  const [date, setDate] = useState<Dayjs | null>(initialDateValue ? dayjs(initialDateValue) : dayjs());
  const [time, setTime] = useState<Dayjs | null>(initialDateValue ? dayjs(initialDateValue) : dayjs());

  const gates = useMemo(() => configurationStore.configuration?.gates || [], []);
  const gatesOptions = gates.map((gate) => ({ label: gate.name, value: gate.name }));

  const defaultGate = !incident ? gatesOptions[0] : undefined;

  const communityProps: SelectProps = { placeholder: 'Select Community', showSearch: false, showArrow: true };

  useEffect(() => {
    if (date && time) {
      debugger;
      const combinedDateTime = date.hour(time.hour()).minute(time.minute()).second(0);
      console.log(form.getFieldsValue());
      form.setFieldValue(['incidentDate'], combinedDateTime);
    }
  }, [date, time, form]);

  const handleDateChange = (newDate: Dayjs | null) => {
    setDate(newDate);
  };

  const handleTimeChange = (newTime: Dayjs | null) => {
    setTime(newTime);
  };

  const disableFutureDates = (current: Dayjs) => {
    return current && current.endOf('day').isAfter(dayjs(), 'day');
  };

  const disableFutureTimes = () => {
    if (!date) {
      return {};
    }

    const now = dayjs();
    if (date.isSame(now, 'day')) {
      return {
        disabledHours: () => {
          const currentHour = now.hour();
          return Array.from({ length: 24 }, (_, i) => i).filter((hour) => hour > currentHour);
        },
        disabledMinutes: (selectedHour: number) => {
          const currentHour = now.hour();
          const currentMinute = now.minute();
          if (selectedHour === currentHour) {
            return Array.from({ length: 60 }, (_, i) => i).filter((minute) => minute > currentMinute);
          }
          return [];
        },
      };
    }

    return {};
  };

  const addressSelect = useAddressSelect({
    value: undefined,
    communityProps,
    onAddressChange: (value: IAddress | null) => {
      form.setFieldValue(['address', 'addressId'], value?.id);
    },
    isOpen: true,
    initData: incident?.address,
    form: form,
    requireCommunity: true,
  });

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <FormWrapper form={form} autoComplete="false" layout="vertical" onFinish={onSave} initialValues={convertToFormValues(incident)}>
      <Row gutter={{ lg: 16, md: 8, xs: 0 }}>
        <Col xs={{ span: 24, order: 3 }} lg={{ span: 8, order: 3 }}>
          {addressSelect.CommunitySelect}
        </Col>
        <Col xs={{ span: 24, order: 4 }} lg={{ span: 8, order: 4 }}>
          <Form.Item name="gate" label="Gate" initialValue={defaultGate?.value}>
            <Select placeholder="Select Gate" options={gatesOptions} defaultValue={defaultGate} allowClear={true} />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 4, order: 1 }}>
          <Form.Item name="incidentDate" hidden />
          <Form.Item label="Date">
            <DatePicker
              allowClear={false}
              format="MM/DD/YYYY"
              placeholder="Select date"
              style={{ width: '100%' }}
              value={date}
              onChange={handleDateChange}
              disabledDate={disableFutureDates}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 4, order: 2 }}>
          <Form.Item label="Time">
            <TimePicker
              format={TIME_h_mma}
              placeholder="Select time"
              style={{ width: '100%' }}
              minuteStep={1}
              allowClear={false}
              use12Hours
              value={time}
              onChange={handleTimeChange}
              disabledTime={disableFutureTimes}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, order: 4 }} lg={{ span: 8, order: 4 }}>
          {addressSelect.StreetSelect}
        </Col>
        <Col xs={{ span: 24, order: 5 }} lg={{ span: 8, order: 5 }}>
          {addressSelect.StreetNumberSelect}
        </Col>
        <Col xs={{ span: 24, order: 6 }} lg={{ span: 8, order: 6 }}>
          {addressSelect.AddressDetailsSelect}
        </Col>
      </Row>
      {incident && <IncidentEditModeActions onCancel={handleCancel} />}
    </FormWrapper>
  );
};

export default IncidentLocationEdit;
